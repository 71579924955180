<template>
  <div class="namecard">
    <NameCard :profile="profile"/>
  </div>
</template>
<script>
import NameCard from "@/components/namecard/NameCard.vue";
export default {
  components: {
    NameCard,
  },
  data() {
    return {
      profile:
        {
          id: 1,
          name: "Fan Syong Yue",
          path: "syongyue",
          role: "Co-Founder, Software Engineer",
          email: "FanSyongYue@WebBricksSG.com",
          phone: "",
          linkedin:"https://www.linkedin.com/in/fansyongyue/",
          facebook: "https://www.facebook.com/mastersyong",
          instagram: "https://www.instagram.com/mooooonbear/",
        },
    }
  }
};
</script>
